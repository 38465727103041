.footer {
    float: left;
    overflow: hidden;
    width: 100%;
}

.footer-topo {
    background-color: #333;
    // float: left;
    // overflow: hidden;
    width: 100%;
    height: 60px;
}

.footer-skew {
    float: left;
    overflow: hidden;
    width: 30%;
    height: 60px;
    margin-left: 30px;
    transform: skewX(-45deg);
    background-color: #eee;
}

.footer-conteudo {
    background-color: #1c1c1c;
    float: left;
    overflow: hidden;
    // padding: 50px 0 10px;
    width: 100%;
}

.footer-conteudo-principal {
    line-height: 23px;
    font-weight: 400;
    font-style: normal;
    color: #000;
    background-color: #ddd;
    width: 30%;
    margin-right: 6%;
    padding: 30px;
    font-size: 15px;
}

.logo-footer {
    width: 100%;
}

.footer-conteudo-center {
    line-height: 23px;
    font-weight: 400;
    font-style: normal;
    color: #fff;
    font-size: 15px;
    width: auto;
    padding: 50px 50px 10px 0;
}

.footer-line-height {
    line-height: 2.5;
}

.footer-develop {
    background: #ddd;
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .footer-skew {
        width: 100%;
    }
    
    .footer-conteudo-principal {
        width: 100%;
        margin-right: 0;
    }

    .logo-footer {
        width: auto;
    }
}

@media (max-width: 575.98px) {
    .footer-conteudo-principal {
        width: 100%;
        margin-right: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .logo-footer {
        width: auto;
    }

    .footer-conteudo-center {
        width: 100%;
        text-align: center;
        padding-right: 0;
    }
}